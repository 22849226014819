import * as React from 'react';
import Layout from '../components/layout';
import { about, centered, contentWrapper, heading } from '../components/layout.module.css';
import '../components/contact.module.css';
import { Seo } from '../components/seo';

const AboutPage = () => {
    return (
        <Layout pageTitle='About'>
            <h1 className={`${heading} ${centered} ${about}`}>About</h1>
            <div className={`${contentWrapper} ${centered} ${about}`}>
                <p>
                    Richard Powell is an interdisciplinary creative professional 
                    with {new Date().getFullYear()-2012} years experience with
                    graphic design, web design, and animation.
                </p>
                <p>
                    With a passion dwelling in the intersection between art and technology,
                     Richard uses cutting edge tools to procedurally and manually coax graphics from hardware.
                    With an interest in digital art dating back to the primordial age of graphics, with elementary programs like Microsoft paintbrush,
                    Richard has spent a lifetime utilizing various softwares to create visually compelling graphics.
                    With a constantly expanding wealth of knowledge in digital art, design, animation,
                     and programming, Richard provides a skillset both technical and creative.
                </p>
                <strong>CONTACT</strong>        
                <p>art@rtpowelldesign.com</p>
            </div>
        </Layout>
    );
};

export default AboutPage;

export const Head = () => <Seo title='About' />;